'use client';

import {
  createContext,
  useContext,
  useState,
} from 'react';

interface TemplateNavigationProviderProps {
  children: React.ReactNode;
}

type TemplateNavigationMap = Record<string, boolean>

interface TemplateNavigationContextState {
  /** Current map of visited templates */
  templateMap: TemplateNavigationMap;
  /** Update current template navigation map */
  addTemplateNavigation: (templateKey: string) => void;
}

const TemplateNavigationDefault: TemplateNavigationMap = {};

const TemplateNavigationContext = createContext<TemplateNavigationContextState>({
  templateMap: TemplateNavigationDefault,
  addTemplateNavigation: () => console.warn('TemplateNavigationProvider not ready'),
});

export function TemplateNavigationProvider({
  children,
}: TemplateNavigationProviderProps) {
  const [templateMap, setMap] = useState<TemplateNavigationMap>(TemplateNavigationDefault);
  const addTemplateNavigation = (key: string) => setMap({ ...templateMap, [key]: true });

  return (
    <TemplateNavigationContext.Provider
      value={{
        templateMap,
        addTemplateNavigation,
      }}
    >
      {children}
    </TemplateNavigationContext.Provider>
  );
}

export function useTemplateNavigation() {
  return useContext(TemplateNavigationContext);
}
