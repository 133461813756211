import { LineupEventVideo } from "@/types/event";
import { MediaSize, StaticVideoSize } from "@/types/media";
import styles from "./EventMedia.module.scss";
import EventImage from "./eventImage/EventImage";
import EventVideo from "./eventVideo/EventVideo";

export interface EventMediaProps
	extends Pick<LineupEventVideo, "video" | "image"> {
	rounded?: boolean;
	/** Strongly recommended to specify size, defaults to `'lineup'`, which is relatively small */
	size?: MediaSize;
	/** Scroll loading videos is opt-in */
	scrollLoading?: true;
}

export default function LineupMedia({
	image,
	rounded,
	size,
	video,
	scrollLoading,
}: EventMediaProps) {
	const imageElement = <EventImage image={image} size={size} />;

	const videoElement = video && (
		<EventVideo
			video={video}
			size={videoSize(size)}
			scrollLoading={scrollLoading}
		>
			{imageElement}
		</EventVideo>
	);

	return (
		<div className={rounded ? styles.rounded : styles.standard}>
			{video ? videoElement : imageElement}
		</div>
	);
}

function videoSize(size?: MediaSize): StaticVideoSize {
	switch (size) {
		case "hero":
			return "large";
		case "feature":
			return "medium";
		case "lineup":
			return "medium";
		case "listing":
			return "medium";
		default:
			return "small";
	}
}
