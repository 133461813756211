import type {
  MediaSize,
} from '@/types/media';

export type NextImageSizeProps = {
  width: number;
  height: number;
} | {
  sizes: string;
}

export function nextImageMediaSize(size?: MediaSize): NextImageSizeProps {
  switch (size) {
    case 'hero':
      return {
        width: 1600,
        height: 1200,
      };
    case 'feature':
      return {
        width: 800,
        height: 600,
      };
    case 'lineup':
      return {
        width: 320,
        height: 240,
      };
    case 'feast':
      return {
        width: 1600,
        height: 900,
      };
    case 'listing':
    default:
      return {
        width: 640,
        height: 480,
      };
  }
}
