import { nextImageMediaSize } from "@/lib/helpers/imageSizes";
import type { ImageAsset, MediaSize } from "@/types/media";
import Image from "next/image";
import styles from "./EventImage.module.scss";

export interface EventImageProps {
	image: ImageAsset;
	size?: MediaSize;
}

export default function EventImage({ image, size }: EventImageProps) {
	const { url, alt = "Image of artist playing at Mona Foma" } = image ?? {};

	if (!url) {
		return null;
	}

	return (
		<Image
			className={styles.image}
			src={url}
			alt={alt}
			{...nextImageMediaSize(size)}
		/>
	);
}
