export const THEME_DARK = "dark";
export const THEME_LIGHT = "light";
export const THEME_DEFAULT = THEME_DARK;

export type Theme = typeof THEME_DARK | typeof THEME_LIGHT;

/**
 * Theme may (should only) be undefined if it hasn't been initialised yet,
 * in that case we currently treat it as "potentially accessible" and default
 * to using accessible content.
 *
 * Override this behaviour by passing `defaultEnabled` argument of `true`,
 * which will instead treat content as enabled until an accessible theme is
 * initialised.
 */
export function useAccessibleContent(
	theme?: Theme,
	defaultEnabled: boolean = false,
): boolean {
	return (theme === undefined && !defaultEnabled) || theme === THEME_LIGHT;
}
