export function templateForPage(pathname: string): string | undefined {
	if (/^\/$/.test(pathname)) {
		return "home";
	}

	if (/^\/program$/.test(pathname)) {
		return "lineup";
	}

	if (/^\/program\/hobart$/.test(pathname)) {
		return "program-hobart";
	}

	if (/^\/program\/launceston$/.test(pathname)) {
		return "program-launceston";
	}

	if (/^\/program\/free$/.test(pathname)) {
		return "free";
	}

	if (/^\/program\/artists$/.test(pathname)) {
		return "artists";
	}

	if (/^\/program\/bundles$/.test(pathname)) {
		return "bundles";
	}

	if (/^\/program\/schedule$/.test(pathname)) {
		return "schedule";
	}

	if (/^\/program\/venues$/.test(pathname)) {
		return "venue-map";
	}

	if (/^\/tickets$/.test(pathname)) {
		return "tickets";
	}

	if (/^\/tickets\/hobart$/.test(pathname)) {
		return "tickets-hobart";
	}

	if (/^\/tickets\/launceston$/.test(pathname)) {
		return "tickets-launceston";
	}

	if (/^\/tickets\/cart$/.test(pathname)) {
		return "cart";
	}

	if (/^\/tickets\/transport$/.test(pathname)) {
		return "transport";
	}

	if (/^\/information/.test(pathname)) {
		return "information";
	}

	if (/^\/program\/saved$/.test(pathname)) {
		return "wishlist";
	}

	if (/^\/search$/.test(pathname)) {
		return "search";
	}

	if (/^\/subscribe$/.test(pathname)) {
		return "subscribe";
	}
}
