"use client";

import { useEffect, useRef } from "react";

import { usePathname } from "next/navigation";

import { templateForPage } from "../analytics/pageView";

// See https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#add_or_remove_an_item_from_a_shopping_cart
export interface GA4EcommerceItem {
	item_id: string;
	item_name: string;
	affiliation?: string;
	coupon?: string;
	currency: "AUD";
	discount?: number;
	item_brand: string;
	item_category: string;
	item_category2?: string;
	item_category3?: string;
	item_category4?: string;
	item_category5?: string;
	item_list_id?: string;
	item_list_name?: string;
	item_variant: string;
	location_id?: string;
	price: number | string;
	quantity: number;
}

// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#select_an_item_from_a_list
export interface GA4EcommerceViewItemList {
	event: "view_item_list";
	ecommerce: {
		item_list_id: string;
		item_list_name: string;
		items: Array<GA4EcommerceItem>;
	};
}

// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#view_item_details
export interface GA4EcommerceSelectItem {
	event: "select_item";
	ecommerce: {
		item_list_id: string;
		item_list_name: string;
		items: Array<GA4EcommerceItem>;
	};
}

// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#view_item_details
export interface GA4EcommerceViewItemDetails {
	event: "view_item";
	ecommerce: {
		items: Array<GA4EcommerceItem>;
	};
}

// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#apply_promotions
export interface GA4ViewPromotion {
	event: "view_promotion";
	ecommerce: {
		creative_name?: string;
		creative_slot?: string;
		promotion_id?: string;
		promotion_name?: string;
		items: Array<GA4EcommerceItem>;
	};
}

// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#apply_promotions
export interface GA4SelectPromotion {
	event: "select_promotion";
	ecommerce: {
		creative_name?: string;
		creative_slot?: string;
		promotion_id?: string;
		promotion_name?: string;
		items: Array<GA4EcommerceItem>;
	};
}

export interface GA4EcommerceAddToCart {
	event: "add_to_cart";
	ecommerce: {
		items: Array<GA4EcommerceItem>;
	};
}

export interface GA4EcommerceRemoveFromCart {
	event: "remove_from_cart";
	ecommerce: {
		items: Array<GA4EcommerceItem>;
	};
}

export interface GA4EcommerceViewCart {
	event: "view_cart";
	ecommerce: {
		currency: "AUD";
		value: number | string;
		items: Array<GA4EcommerceItem>;
	};
}

export interface GA4EcommerceBeginCheckout {
	event: "begin_checkout";
	ecommerce: {
		items: Array<GA4EcommerceItem>;
	};
}

export interface GA4EcommerceAddPaymentInfo {
	event: "add_payment_info";
	ecommerce: {
		currency: "AUD";
		value: number | string;
		payment_type: "Credit Card";
		items: Array<GA4EcommerceItem>;
	};
}

export interface GA4EcommercePurchase {
	event: "purchase";
	cartCreatedSecondsAgo?: number;
	cartExpiresInSeconds?: number;
	ecommerce: {
		transaction_id: string;
		affiliation?: string;
		value: number | string;
		tax: number | string;
		shipping?: number | string;
		currency: "AUD";
		coupon?: string;
		items: Array<GA4EcommerceItem>;
	};
}

/**
 * Trigger the custom `VirtualPageview` event to track page views on
 * client-side route changes, invoke in _app. Stores the prior page URL path so
 * we can report on the prior page when a new page is loaded.
 *
 * See https://nextjs.org/docs/api-reference/next/router#routerevents
 */
export function useTrackVirtualPageViewEffect() {
	const previousPathname = useRef<string>();
	const pathName = usePathname();


	useEffect(() => {
		// Don't do anything unless we have the current pathName
		if (!pathName) {
			return;
		}

		const origin = window.location.origin;

		// Track detailed route info for client-side navigation page loads, from a
		// prior path to a new one
		if (previousPathname.current && pathName !== previousPathname.current) {
			window.dataLayer?.push({
				event: "custom.route.change",
				route: {
					current: origin + pathName,
					previous: origin + previousPathname.current,
					title: document.title,
					template: templateForPage(pathName),
				},
			});
		}
		// Log minimal route info for initial browser page load
		else if (!previousPathname.current) {
			window.dataLayer?.push({
				route: {
					current: origin + pathName,
					title: document.title,
					template: templateForPage(pathName),
				},
			});
		}

		previousPathname.current = pathName;
	}, [pathName]);
}

export function trackCustomButtonClick({
	linkClickText,
	linkClickURL,
	buttonGroup = "button",
	elementSection,
	buttonType,
	buttonVariation,
}: {
	linkClickText: string;
	linkClickURL?: string;
	buttonGroup: "button" | "cta";
	elementSection?: string;
	buttonType?: string;
	buttonVariation?: string;
}) {
	window.dataLayer?.push({
		event: "custom.button.click",
		group: buttonGroup,
		info: undefined, // Undefined on purpose
		data: {
			linkClickText,
			linkClickURL,
			buttonType,
			buttonVariation,
			section: elementSection,
			value: undefined, // Undefined on purpose
		},
	});
}

export function trackCustomGenericInteraction({
	action,
	label,
}: {
	action: string;
	label?: string;
}) {
	window.dataLayer?.push({
		event: "custom.generic.interaction",
		action,
		label,
	});
}

export function trackCustomFilter({
	action,
	label,
	parentLabel,
	data,
}: {
	action: string;
	label: string;
	parentLabel?: string;
	data?: object;
}) {
	window.dataLayer?.push({
		event: `custom.filter.${action}`,
		label,
		parentLabel,
		data,
	});
}

export function trackCustomToggle({
	on,
	group,
	label,
	parentLabel,
	info,
	data,
}: {
	on: boolean;
	group: string;
	label?: string;
	parentLabel?: string;
	info?: object;
	data?: object;
}) {
	window.dataLayer?.push({
		event: `custom.toggle.${on ? "off" : "on"}`,
		group,
		label,
		parentLabel,
		info,
		data,
	});
}

export function trackCustomAccordionClick({
	accordionName,
	accordionGroup,
	elementSection,
	accordionPosition,
}: {
	accordionName: string;
	accordionGroup?: string;
	elementSection?: string;
	accordionPosition?: string;
}) {
	window.dataLayer?.push({
		event: "custom.accordion.click",
		label: accordionName,
		group: accordionGroup,
		info: undefined,
		data: {
			value: undefined,
			section: elementSection,
			position: accordionPosition,
		},
	});
}

export function trackCustomFormComplete({
	group,
	formType,
	formVariant,
}: {
	group: "Subscribe" | "Checkout";
	formType: "Subscribe" | "Checkout";
	formVariant?: string;
}) {
	window.dataLayer?.push({
		event: "custom.form.complete",
		group,
		label: undefined,
		parentLabel: undefined,
		info: {
			time: Date.now(),
		},
		data: {
			formType,
			formVariant,
			value: undefined,
		},
		processId: undefined,
	});
}

export function trackCustomWishlistShareEvent({
	event,
	group,
	method,
	bookmarksSessionCookie,
}: {
	event: "custom.wishlist.share";
	group: "wishlist_share";
	method: "url" | "copy_to_clipboard" | "email";
	bookmarksSessionCookie: string;
}) {
	window.dataLayer?.push({
		event,
		group,
		label: undefined,
		parentLabel: undefined,
		info: {
			time: Date.now(),
		},
		data: {
			method,
			bookmarksSessionCookie,
		},
		processId: undefined,
	});
}

export function trackGA4EcommerceViewItemList(event: GA4EcommerceViewItemList) {
	const data: GA4EcommerceViewItemList = {
		...event,
		ecommerce: {
			item_list_id: event.ecommerce.item_list_id,
			item_list_name: event.ecommerce.item_list_name,
			items: event.ecommerce.items.map((item, index) => {
				return {
					...item,
					index,
					price: formatCurrency(item.price),
				};
			}),
		},
	};
	window.dataLayer?.push({ ecommerce: null }); // Clear previous ecommerce object
	window.dataLayer?.push(data);
}

export function trackGA4EcommerceSelectItem(event: GA4EcommerceSelectItem) {
	const data: GA4EcommerceSelectItem = {
		...event,
		ecommerce: {
			item_list_id: event.ecommerce.item_list_id,
			item_list_name: event.ecommerce.item_list_name,
			items: event.ecommerce.items.map((item, index) => {
				return {
					...item,
					index,
					price: formatCurrency(item.price),
				};
			}),
		},
	};
	window.dataLayer?.push({ ecommerce: null }); // Clear previous ecommerce object
	window.dataLayer?.push(data);
}

export function trackGA4EcommerceViewPromotion(event: GA4ViewPromotion) {
	const data: GA4ViewPromotion = {
		...event,
		ecommerce: {
			creative_name: event.ecommerce.creative_name,
			creative_slot: event.ecommerce.creative_slot,
			promotion_id: event.ecommerce.promotion_id,
			promotion_name: event.ecommerce.promotion_name,
			items: event.ecommerce.items.map((item, index) => {
				return {
					...item,
					index,
					price: formatCurrency(item.price),
				};
			}),
		},
	};
	window.dataLayer?.push({ ecommerce: null }); // Clear previous ecommerce object
	window.dataLayer?.push(data);
}

export function trackGA4EcommerceSelectPromotion(event: GA4SelectPromotion) {
	const data: GA4SelectPromotion = {
		...event,
		ecommerce: {
			creative_name: event.ecommerce.creative_name,
			creative_slot: event.ecommerce.creative_slot,
			promotion_id: event.ecommerce.promotion_id,
			promotion_name: event.ecommerce.promotion_name,
			items: event.ecommerce.items.map((item, index) => {
				return {
					...item,
					index,
					price: formatCurrency(item.price),
				};
			}),
		},
	};
	window.dataLayer?.push({ ecommerce: null }); // Clear previous ecommerce object
	window.dataLayer?.push(data);
}

export function trackGA4EcommerceViewItemDetails(
	event: GA4EcommerceViewItemDetails,
) {
	const data: GA4EcommerceViewItemDetails = {
		...event,
		ecommerce: {
			items: event.ecommerce.items.map((item, index) => {
				return {
					...item,
					index,
					price: formatCurrency(item.price),
				};
			}),
		},
	};
	window.dataLayer?.push({ ecommerce: null }); // Clear previous ecommerce object
	window.dataLayer?.push(data);
}

export function trackGA4EcommerceAddToCart(event: GA4EcommerceAddToCart) {
	const data: GA4EcommerceAddToCart = {
		...event,
		ecommerce: {
			items: event.ecommerce.items.map((item, index) => {
				return {
					...item,
					index,
					price: formatCurrency(item.price),
				};
			}),
		},
	};
	window.dataLayer?.push({ ecommerce: null }); // Clear previous ecommerce object
	window.dataLayer?.push(data);
}

export function trackGA4EcommerceRemoveFromCart(
	event: GA4EcommerceRemoveFromCart,
) {
	const data: GA4EcommerceRemoveFromCart = {
		...event,
		ecommerce: {
			items: event.ecommerce.items.map((item, index) => {
				return {
					...item,
					index,
					price: formatCurrency(item.price),
				};
			}),
		},
	};
	window.dataLayer?.push({ ecommerce: null }); // Clear previous ecommerce object
	window.dataLayer?.push(data);
}

export function trackGA4EcommerceViewCart(event: GA4EcommerceViewCart) {
	const data: GA4EcommerceViewCart = {
		...event,
		ecommerce: {
			...event.ecommerce,
			value: formatCurrency(event.ecommerce.value),
			items: event.ecommerce.items.map((item, index) => {
				return {
					...item,
					index,
					price: formatCurrency(item.price),
				};
			}),
		},
	};
	window.dataLayer?.push({ ecommerce: null }); // Clear previous ecommerce object
	window.dataLayer?.push(data);
}

export function trackGA4EcommerceBeginCheckout(
	event: GA4EcommerceBeginCheckout,
) {
	const data: GA4EcommerceBeginCheckout = {
		...event,
		ecommerce: {
			items: event.ecommerce.items.map((item, index) => {
				return {
					...item,
					index,
					price: formatCurrency(item.price),
				};
			}),
		},
	};
	window.dataLayer?.push({ ecommerce: null }); // Clear previous ecommerce object
	window.dataLayer?.push(data);
}

export function trackGA4EcommerceAddPaymentInfo(
	event: GA4EcommerceAddPaymentInfo,
) {
	const data: GA4EcommerceAddPaymentInfo = {
		...event,
		ecommerce: {
			...event.ecommerce,
			value: formatCurrency(event.ecommerce.value),
			items: event.ecommerce.items.map((item, index) => {
				return {
					...item,
					index,
					price: formatCurrency(item.price),
				};
			}),
		},
	};
	window.dataLayer?.push({ ecommerce: null }); // Clear previous ecommerce object
	window.dataLayer?.push(data);
}

export function trackGA4EcommercePurchase(event: GA4EcommercePurchase) {
	const data: GA4EcommercePurchase = {
		...event,
		ecommerce: {
			...event.ecommerce,
			value: formatCurrency(event.ecommerce.value),
			tax: formatCurrency(event.ecommerce.tax),
			shipping: formatCurrency(event.ecommerce.shipping),
			items: event.ecommerce.items.map((item, index) => {
				return {
					...item,
					index,
					price: formatCurrency(item.price),
				};
			}),
		},
	};
	window.dataLayer?.push({ ecommerce: null }); // Clear previous ecommerce object
	window.dataLayer?.push(data);
}

/**
 * For some mind-boggling reason, it seems GA4 cannot interpret plain numbers
 * like 60 as valid currency values, and instead expects all currency values to
 * include cents like so 60.00 – but we can only ensure this by formatting
 * amounts as a string and forcibly adding cent values.
 *
 * Returns `undefined` if the given amount is not a invalid number.
 */
function formatCurrency(amount: number | string | undefined): string {
	const amountFloat = parseFloat(String(amount));
	if (Number.isNaN(amountFloat)) {
		return "";
	} else {
		return amountFloat.toFixed(2);
	}
}
