/**
 * Ordered list of festival phases
 */
export enum ProgramPhase {
	BrandAnnounce,
	EarlyAnnounce,
	ProgramAnnounce,
	GeneralOnsale,
	Resales,
	WinterFeast,
	FestivalMode,
	FestivalLiveStream,
	Archive,
}

/**
 * Exclusive types
 */
export enum ExclusiveTypes {
	MonaFoma = 'MonaFoma',
	Festival = 'Festival',
	Rising = 'Rising',
	Vivid = 'Vivid',
}

/**
 * Supported types of events
 */
export enum EventTypes {
	Ticketed = 'ticketed',
	Free = 'free',
	WinterFeast = 'winterFeast',
	SuperEvent = 'superEvent',
}

/**
 * Venue categories / types
 */
export enum VenueTypes {
	Hobart = 'hobart',
	Launceston = 'launceston',
	Sheffield = 'sheffield',
}

export type VenueTypeValues = `${VenueTypes}`
