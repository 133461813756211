"use client";

import { useTheme } from "@/components/context/ThemeContext";
import { useAccessibleContent } from "@/lib/helpers/theme";
import type { StaticVideoAsset, StaticVideoSize } from "@/types/media";
import throttle from "lodash/throttle";
import type React from "react";
import { createRef, useEffect, useState } from "react";
import styles from "./EventVideo.module.scss";

export interface EventVideoProps {
	video: StaticVideoAsset;
	size?: StaticVideoSize;
	children: React.ReactNode;
	scrollLoading?: true;
}

const WINDOW_BELOW_OFFSET = 300;
const RESIZE_SCROLL_THROTTLE = 25;

export default function EventVideo({
	video,
	children,
	size = "small",
	scrollLoading,
}: EventVideoProps) {
	const { theme } = useTheme();

	const videoContainerRef = createRef<HTMLDivElement>();
	const videoRef = createRef<HTMLVideoElement>();

	const [enabled, setEnabled] = useState(false);
	const [playing, setPlaying] = useState(false);

	const accessibleContent = useAccessibleContent(theme);
	const playVideo = !accessibleContent && (!scrollLoading || enabled);
	const showImage = !playVideo || !playing;

	useEffect(() => {
		if (scrollLoading) {
			if (inScreenEnabled(videoContainerRef)) {
				setEnabled(true);
			}

			const onScrollThrottled = throttle(
				() => {
					const inScreen = inScreenEnabled(videoContainerRef);

					if (inScreen !== undefined) {
						setEnabled(inScreen);
					}
				},
				RESIZE_SCROLL_THROTTLE,
				{
					leading: false,
					trailing: true,
				},
			);

			window.addEventListener("resize", onScrollThrottled);
			window.addEventListener("scroll", onScrollThrottled);

			return () => {
				if (onScrollThrottled) {
					window.removeEventListener("resize", onScrollThrottled);
					window.removeEventListener("scroll", onScrollThrottled);
				}
			};
		}
	}, [scrollLoading, videoContainerRef, video]);

	useEffect(() => {
		if (!playVideo) {
			setPlaying(false);
		}
	}, [playVideo, enabled, accessibleContent]);

	return (
		<div className={styles.container}>
			<div className={showImage ? styles.image : styles.imageHidden}>
				{children}
			</div>
			<div ref={videoContainerRef} className={styles.videoContainer}>
				{playVideo && (
					<video
						ref={videoRef}
						className={styles.video}
						src={videoSizeSrc(size, video)}
						onPlay={() => setPlaying(true)}
						autoPlay
						muted
						loop
						playsInline
					/>
				)}
			</div>
		</div>
	);
}

function inScreenEnabled({ current }: React.RefObject<HTMLElement>) {
	if (current) {
		const { top, bottom } = current.getBoundingClientRect();

		return (
			top < window.innerHeight + WINDOW_BELOW_OFFSET &&
			bottom > 0 - WINDOW_BELOW_OFFSET
		);
	}
}

export function videoSizeSrc(size: StaticVideoSize, video: StaticVideoAsset) {
	const { small, medium, large } = video;

	switch (size) {
		case "small":
			return small;
		case "medium":
			return medium;
		case "large":
			return large;
	}
}
