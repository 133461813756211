"use client";

import { createContext, useContext, useEffect, useState } from "react";

import type React from "react";

import { LOCAL_STORAGE_THEME_KEY } from "@/lib/config/localStorage";
import {
	THEME_DARK,
	THEME_DEFAULT,
	THEME_LIGHT,
	Theme,
} from "@/lib/helpers/theme";

interface ThemeProviderProps {
	children: React.ReactNode;
}

interface ThemeContextState {
	/** Current theme setting  */
	theme?: Theme;
	/** Change / set the current theme */
	setTheme: (theme: Theme) => any;
}

const ThemeContext = createContext<ThemeContextState>({
	theme: undefined,
	setTheme: () => console.warn("ThemeProvider not ready"),
});

export function ThemeProvider({ children }: ThemeProviderProps) {
	const [theme, setTheme] = useState<Theme>(THEME_DEFAULT);
	const [restored, setRestored] = useState<boolean>(false);

	useEffect(() => {
		const initial = initialTheme();

		saveAndApplyTheme(initial);
		setTheme(initial);
		setRestored(true);
	}, []);

	useEffect(() => {
		if (restored) {
			saveAndApplyTheme(theme);
		}
	}, [theme]);

	return (
		<ThemeContext.Provider
			value={{
				theme,
				setTheme,
			}}
		>
			{children}
		</ThemeContext.Provider>
	);
}

export function useTheme() {
	return useContext(ThemeContext);
}

function saveAndApplyTheme(theme: Theme): void {
	// Update application of theme (i.e. to dom via global class)
	applyThemeClass(theme);

	// Write to local storage whenever theme changes
	window.localStorage.setItem(LOCAL_STORAGE_THEME_KEY, theme);
}

/**
 * Track the theme class on the document body
 * @param theme
 */
function applyThemeClass(theme: Theme): void {
	document.documentElement.classList.remove(THEME_DARK, THEME_LIGHT);
	document.documentElement.classList.add(theme);
}

function initialTheme(): Theme {
	// Attempt to fetch default from saved state (avoids re-writing with useEffect)
	if (typeof window !== "undefined" && window.localStorage) {
		const savedTheme = window.localStorage.getItem(LOCAL_STORAGE_THEME_KEY);

		// Check for valid saved value
		if (
			savedTheme &&
			(savedTheme === THEME_DARK || savedTheme === THEME_LIGHT)
		) {
			return savedTheme;
		}
	}

	return THEME_DEFAULT;
}
