import React from 'react';

export type ElementType = (
  'div'
  | 'article'
  | 'section'
  | 'span'
  | 'h2'
  | 'h3'
  | 'h4'
);

export interface ElementProps {
  children?: React.ReactNode;
  className?: string;
}

export interface ElementByTypeProps extends ElementProps {
  type?: ElementType;
}

export default function ElementByType({
  type = 'div',
  ...props
}: ElementByTypeProps) {
  return React.createElement(type, props);
}
